import tb from "@/features/toolBox.js";
//var chalk = require("chalk");

export default {
  install: (app, options) => {
    // available for all components '$_log' method ('$' - is a naming convention)
    //just wrap function from features toolBox...
    app.config.globalProperties.$_log = tb._log;
    app.config.globalProperties.$formatDate = tb.formatDate;
    app.config.globalProperties.$toHHMMSS = tb.toHHMMSS;
    app.config.globalProperties.$HHMMSStoTxt = tb.HHMMSStoTxt;
    app.config.globalProperties.$time2sec = tb.time2sec;
    app.config.globalProperties.$toCamelCase = tb.toCamelCase;
    app.provide("tb", options);
  },
};
