<template>
  <div v-if="dataReady" class="dlg_c_0">
    Thanks! You have just confirmed your account. Please open your mobile
    application to use the product.
  </div>
  <div v-else>account not confirmed yet ....</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import userSrv from "@/services/userService.js"; // access to the APIs

export default {
  name: "confirmAccount",
  components: {},
  props: ["token"],
  data() {
    return {
      dataReady: false,
    };
  },
  created() {
    this.getData((err, r) => {
      if (err) {
        this.$_log(err);
      } else {
        this.$_log("confirmAccount] created after getData r:", r);
        this.$_log(
          "confirmAccount] created after getData just before router push to login"
        );
        this.$router.push({
          name: "home",
        });
      }
    });
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    getData(callback) {
      this.$_log("confirmAccount getData...");

      //
      userSrv
        .confirmUserAccount(this.token)
        .then((response) => {
          this.$_log("asw response.data:", response.data);
          //todo: add notification !!!

          var n = {
            type: "success",
            message: "You have just confirmed your account.",
          };
          this.addNotificationMessage(n);
          callback(null, "ok");
        })
        .catch((error) => {
          var n;
          if (typeof error.response != "undefined") {
            this.$_log("asw error:", error.response.data);
            n = {
              type: "problem",
              message:
                "Account can't be confirmed... error:" + error.response.message,
            };
            this.addNotificationMessage(n);
          } else {
            this.$_log("asw error:", error);
            n = {
              type: "problem",
              message: "Account can't be confirmed... error:" + error,
            };
            this.addNotificationMessage(n);
          }
        });
      //
    },
  },
};
</script>

<style scoped></style>
