import { createStore } from "vuex";

import * as notificationModule from "@/store/modules/generic/notifications.js";
import * as dbToolsModule from "@/store/modules/generic/dbTools.js";
import * as userModule from "@/store/modules/pages/users.js";
import * as clientModule from "@/store/modules/pages/clients.js";
import * as streamModule from "@/store/modules/pages/streams.js";
import * as subscriptionsModule from "@/store/modules/pages/subscriptions.js";

import tb from "@/features/toolBox.js";

export default createStore({
  modules: {
    notificationModule,
    dbToolsModule,
    userModule,
    clientModule,
    streamModule,
    subscriptionsModule,

    // namespace available for all other module stores ==>
    aswGlobalStoreSpace: {
      namespaced: true,

      actions: {
        // global mesage fuction can be called like this: dispatch("asw_log", [namespacedContextString, payloadString], {root: true,});
        asw_log: {
          root: true,
          handler(namespacedContext, payload) {
            if (typeof namespacedContext != "undefined") {
              var [str, obj, importance] = payload;
              tb._log("GStore] " + str, obj, importance);
            }
          },
        },
      },
    },
  },
  //
  //
});
