<template>
  <div class="dlg_form_container">
    <div class="dlg_c_l1">
      <p class="dlg_form_header">{{ msg }}</p>
    </div>
    <div class="dlg_c_l2">
      <form @submit.prevent="onSubmit">
        <input type="hidden" name="token" :value="rpToken" />
        <div class="ms_inputbox_0">
          <label>Enter Password</label>
          <div class="ms_inputbox_1">
            <input
              v-model="mCurrentUser.password"
              type="password"
              placeholder="password"
            />
            <fieldset aria-hidden="true">
              <legend><span>Enter Password</span></legend>
            </fieldset>
          </div>
        </div>
        <div id="defaultPassMsg">{{ passwordRequirementMsg }}</div>
        <div id="password_Msg" class="errorMsg"></div>

        <div class="ms_inputbox_0">
          <label>Confirm password</label>
          <div class="ms_inputbox_1">
            <input
              v-model="mCurrentUser.passwordC"
              type="password"
              placeholder="password"
            />
            <fieldset aria-hidden="true">
              <legend><span>Confirm password</span></legend>
            </fieldset>
          </div>
        </div>
        <div id="passwordC_Msg" class="errorMsg"></div>

        <div class="dlg_submit_line">
          <button type="submit" class="dlg_submit_btn pwd_reset">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
//import { mapState } from "vuex";
import { mapGetters, mapActions } from "vuex";
import Validator from "validatorjs";
import config from "@/config/config.js";

export default {
  name: "ResetPasswordRequestDialog",
  props: {
    msg: String,
    rpToken: String,
  },
  data() {
    return {
      mCurrentUser: {
        email: "",
        password: "",
        passwordC: "",
      },
      passwordRequirement: config.passwordRequirement,
      passwordRequirementMsg: config.passwordRequirementMsg,
      //  "The password must contain at least 6 characters, 1 lowercase letter, 1 uppercase letter, 1 digit, and 1 special character.",
    };
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "currentClientId",
    ]),
  },
  methods: {
    doValidation(usr) {
      return new Promise((resolve, reject) => {
        let data = {
          password: usr.password,
          passwordC: usr.passwordC,
        };

        this.$_log("data to validate:", data);

        let rules = {
          password: [
            "required",
            this.passwordRequirement,
            //"regex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\!\\@\\#\\$%\\^&\\*])(?=.{6,})/",
          ],
          passwordC: "same:password",
        };

        for (const [key, value] of Object.entries(data)) {
          console.log(`${key}: ${value}`);
          let o = this.$el.querySelector("#" + key + "_Msg");
          o.innerText = "";
        }

        let validation = new Validator(data, rules, {
          "regex.password": this.passwordRequirementMsg,
          same: "The password and confirm password fields must match.",
        });

        if (validation.passes()) {
          this.$_log("all good lets go ahead ....");
          resolve(usr);
        } else {
          this.$_log("ook we have problems ...:", validation);
          let str = "";
          let p = 0;
          for (const [key, value] of Object.entries(validation.errors.all())) {
            console.log("error key:" + key + "value:" + value);
            if (key == "password") {
              p = 1;
            }
            let o = this.$el.querySelector("#" + key + "_Msg");
            o.innerText = value;
            o.style.marginBottom = "25px";
            str = str + value + " ";
          }

          let dpm = this.$el.querySelector("#defaultPassMsg");
          if (p == 1) {
            console.log("hide");
            dpm.style.display = "none";
          } else {
            console.log(".....");
            dpm.style.display = "block";
          }

          this.$_log("problems str:" + str);
          reject(str);
        }
      });
    },
    onSubmit() {
      const usr = {
        password: this.mCurrentUser.password,
        passwordC: this.mCurrentUser.passwordC,
        token: this.rpToken,
      };

      ////
      this.doValidation(usr)
        .then(() => {
          this.$_log("ResetPassword.vue] onSubmit - User:", usr);
          this.$store
            .dispatch("userModule/resetPassword", usr)
            .then(() => {
              this.$_log("ResetPassword.vue] onSubmit - then ...:");
              if (this.isUserLoggedIn) {
                this.$router.push({
                  name: "userCreationsView",
                  params: {
                    pageNr: 0,
                    ClientId: this.currentClientId,
                  },
                });
              } else {
                this.$router.push({
                  name: "home",
                });
              }
            })
            .catch((error) => {
              this.$_log("ResetPassword onSubmit error:", error);
            });
        })
        .catch((error) => {
          this.$_log(
            "uups form data did not pass validation .... error:",
            error
          );
        });
      ////
    },
    //...mapActions("notificationModule", ["remove"]),
    ...mapActions("userModule", ["login"]),
  },
};
</script>

<style src="@/assets/css/dialogform_0.css"></style>
<style scoped>
#defaultPassMsg {
  font-size: 12px;
  /*color: red;*/
  text-align: left;
  padding-bottom: 24px;
}
</style>
