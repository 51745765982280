<template>
  <div class="top-placeholder">
    <div class="tpl">
      <div class="tpl1">
        <template v-if="isUserLoggedIn && displayLoginButton">
          <router-link :to="'/userCreations/0/' + currentClientId">
            <!-- <div class="mslogo"></div> -->
            <img :src="getImageUrl('muzaicai_black.png')" class="mai_logo" />
          </router-link>
        </template>
        <template v-else>
          <a href="https://muzaic.ai/"
            ><!-- <div class="invisibleLogoButton" /> -->
            <img :src="getImageUrl('muzaicai_black.png')" class="mai_logo" />
          </a>
        </template>
      </div>
      <div class="tpl2">
        <template v-if="this.currentRouteName == 'home'">
          <div class="tpl3">
            <div class="tpl_nav"></div>
          </div>
        </template>
        <template v-else>
          <template v-if="isUserLoggedIn && displayLoginButton">
            <div class="tpl3">
              <span>&nbsp;</span>
              <UserTopNavBar />
            </div>
          </template>
          <template v-if="displayLoginButton">
            <div class="tpl3">
              <router-link v-if="!isUserLoggedIn" to="/login" class="button"
                >login</router-link
              >
              <template v-else>
                <!-- <UserProfileNavBar :title="currentUserEmail" /> -->
                <UserProfileNavBar :title="titleToBeDisplayed" />
              </template>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import UserTopNavBar from "@/components/navigation/UserTopNavBar.vue";
import UserProfileNavBar from "@/components/navigation/UserProfileNavBar.vue";
import { mapGetters } from "vuex";

export default {
  name: "globalHeader",
  components: {
    UserProfileNavBar,
    UserTopNavBar,
  },
  props: {
    msg: String,
  },
  data() {
    return { mobileResolutionTriggerV: "600px" };
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "currentUsername",
      "currentUserEmail",
      "userType",
      "currentUserId",
      "currentClientFN",
      "currentClientLN",
      "currentClientId",
    ]),
    ...mapGetters("streamModule", ["getCurrentPage"]),

    mobileResolutionTrigger() {
      return "min-width: 640px";
    },

    currUserAbrev() {
      let usrAbrev = "";
      if (this.currentClientFN != "") {
        usrAbrev += this.currentClientFN[0];
      }
      if (this.currentClientLN != "") {
        usrAbrev += this.currentClientLN[0];
      }

      if (usrAbrev == "") {
        usrAbrev = "...";
      }
      return usrAbrev.toUpperCase();
    },
    titleToBeDisplayed() {
      let str = this.currentClientFN + " " + this.currentClientLN;
      //this.$_log("--->str:" + str);

      if (str == " ") {
        return this.currentUsername;
      } else {
        return this.currentClientFN + " " + this.currentClientLN;
      }

      //return "123";
    },

    currentRouteName() {
      return this.$route.name;
    },
    dashboardPath() {
      return "/userdashboard/" + this.currentUserId;
    },
    displayLoginButton() {
      /*
      if (
        this.currentRouteName != "login" &&
        this.currentRouteName != "register" &&
        this.currentRouteName != "resetPasswordRequest" &&
        this.$route.name != "resetPassword"
      ){return true;}else{return false;}
	  */
      if (this.$route.meta.internalViewWithoutLogin == true) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    getImageUrl: function (imgName) {
      //imgName = "note1.svg"; //"lock-keyhole.svg"; //"logo.png";
      let images = require.context("@/assets/", false, /\.png$|\.jpg$|\.svg$/);
      return images("./" + imgName);

      //this.$_log(imgName);
      //return "@/assets/Google.svg"; // + imgName;
    },
    logout() {
      this.$_log("ASW lets logout!");
      this.$store
        .dispatch("userModule/logout")
        .then(() => {
          this.$_log("LoginDialog.vue] - user logged out !");

          this.$router.push({
            name: "home",
          });
        })
        .catch((error) => {
          //todo: use notification instead!
          this.$router.push({
            name: "ErrorDisplay",
            params: { error: error },
          });
        });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 640px) {
  .tpl {
    justify-content: space-between;
  }
  .tpl1 {
    justify-content: center;
    padding: 0px 0px 0px 0px;
  }
  .tpl2 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .tpl3 {
    flex-wrap: wrap;
  }
  .topNavBar {
    display: none;
  }
}

@media only screen and (min-width: 640px) {
  .tpl {
    justify-content: space-between;
  }
  .tpl1 {
    padding: 0px 0px 0px 0px;
  }
  .tpl2 {
    flex-wrap: wrap;
    justify-content: right;
  }
  .topNavBar {
    display: flex;
  }
}

.top-placeholder {
  grid-area: top-placeholder;

  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.tpl {
  display: flex;
  flex-wrap: wrap;
  max-width: var(--general-maximal-width);
  min-width: var(--general-minimal-width);
  margin: 44px 0px 0px 0px;
  padding: 0px 24px 0px 24px;

  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0px;

  align-items: baseline;
  flex-grow: 1;
  /*color: #ffffff;*/
}

.tpl a {
  color: var(--main-gray500-color);
  display: flex;
}

.tpl a.router-link-exact-active {
  color: var(--main-gray500-color);
}

.tpl1 {
  display: flex;
  align-items: flex-end;
}
.tpl2 {
  display: flex;
  /*padding-right: 30px;*/
}

.tpl_nav {
  /*position: relative;*/
  display: inline-block;
  width: 140px;
  color: #1a1a1a;

  /* ---- */
  /*border: rgb(168, 173, 4) 3px solid;*/
}

.tpl3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.invisibleLogoButton {
  width: 100%;
  height: 100%;
}

.mai_logo {
  position: relative;
  top: 4px;
  cursor: pointer;
  width: 212px;
}

.tpl3 span {
  opacity: 1;
}
</style>
