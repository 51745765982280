<template>
  <div class="about">
    <h1>The future of personalized music</h1>
    <p>
      <a href="https://muzaic.ai/">
        Muzaic is not just an idea. It is a question that asks how technology
        and innovation can change the possibilities of music and how these
        changes can enhance human flourishing in the future.
      </a>
    </p>
  </div>
</template>
<style scoped>
a {
  color: var(--main-gray900-color);
}
.about {
  max-width: 600px;
  max-height: 500px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr;
  justify-content: center;
  align-items: baseline;
}
</style>
