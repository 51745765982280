<template>
  <div class="gridAdminPanelContainer">
    <globalHeader msg="" />
    <div
      :class="
        'main-container ' +
        getCpontentSeparation(this.$route.meta.contentSeperation)
      "
    >
      <router-view />
    </div>
    <globalFooter msg="" />
  </div>
</template>

<script>
//:style="getCpontentSeparation(this.$route.meta.contentSeperation)"
import globalHeader from "@/components/globalHeader.vue";
import globalFooter from "@/components/globalFooter.vue";

export default {
  name: "AdminPanel",
  components: {
    globalHeader,
    globalFooter,
  },
  props: {
    msg: String,
  },
  methods: {
    getCpontentSeparation(contentSeperation) {
      if (typeof contentSeperation == "undefined") {
        contentSeperation = "L";
      }
      let s = "";
      switch (contentSeperation) {
        case "L": {
          //s = "--menu-content-seperation-L";
          s = "main-container-L";
          break;
        }
        case "S": {
          //s = "--menu-content-seperation-S";
          s = "main-container-S";
          break;
        }
        default: {
          //s = "--menu-content-seperation-L";
          s = "main-container-Default";
          break;
        }
      }
      //return " padding-top: var(" + s + ");";
      return s;
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 640px) {
  .gridAdminPanelContainer {
    grid-template-rows: 94px 1fr;
  }
  .main-container {
    padding-top: var(--menu-content-seperation-L);
  }
}
@media only screen and (max-width: 640px) {
  .gridAdminPanelContainer {
    grid-template-rows: 94px 1fr;
  }
  .main-container {
    padding-top: var(--menu-content-seperation-S);
  }
}
.gridAdminPanelContainer {
  display: grid;
  grid-template-columns: 1fr;

  gap: 0px 0px;
  grid-auto-flow: row dense;

  grid-template-areas:
    "top-placeholder"
    "main-container";

  width: calc(100vw - var(--global-left-margin));
  height: 100vh;

  min-width: 360px;
  /*max-width: 1360px;*/
}

.main-container {
  grid-area: main-container;
  min-height: 650px;
  display: flex;
  justify-content: center;
}

.main-container-Default {
  padding-top: var(--menu-content-seperation);
}

/* ========================================================== */
/* ========================================================== */
/* ========================================================== */

/* ASW AdminPanel grid << */

.nav-title {
  position: absolute;
  top: 20px;
  left: 94px;
  text-align: left;

  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
</style>
