<template>
  <div v-if="showFooter" class="bottom-placeholder">
    <div class="bpl">
      <div class="bpl1">
        <router-link to="/"><div class="invisibleLogoButton" /></router-link>
      </div>
      <div class="bpl2">
        <div class="bpl_nav">More from muzaic</div>
      </div>
      <div class="bpl3">
        <div class="bpl_nav">Company info</div>
      </div>
    </div>

    <div class="bpl" style="top: 70%">
      <div class="bpl2a">
        <div>Privacy Policy</div>
        <div>Terms of Use</div>
      </div>
      <div class="bpl2a">
        <div>Muzaic iOS app</div>
        <div>Muzaic Android app</div>
        <div>Muzaic.biz (soon)</div>
      </div>
      <div class="bpl3a">
        <div>About us</div>
        <div>Contact us</div>
        <div>FAQ</div>
      </div>
    </div>
  </div>
</template>

<script>
/* according to the bug reported by Olek 2023-07 muzaic.studio should not have footer .... ok */
export default {
  name: "globalFooter",
  props: {
    msg: String,
  },
  data() {
    return {
      showFooter: false,
    };
  },
};
</script>

<style scoped>
.bottom-placeholder {
  grid-area: bottom-placeholder;
  position: relative;
  background-color: transparent;
  background-image: radial-gradient(
    180% 200px at 50% 0%,
    #ffffff 30%,
    #668eff 68%,
    #000f7a 140%
  );
}

.bpl {
  position: absolute;
  display: flex;
  width: 1100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 0px;
  color: #ffffff;
  padding: 1px;

  /* ---- */
  /*border: blue 1px solid;*/
}

.bpl a {
  font-weight: bold;
  color: #2c3e50;
}

.bpl a.router-link-exact-active {
  color: #2c3e50;
}

.bpl1 {
  /*border: 3px solid #6e9438;*/
  /*position: absolute;*/
  background-image: url("@/assets/muzaic.studio-white1-1024x131.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 200px;

  width: 20%;

  /* ---- */
  /*border: rgb(58, 116, 87) 3px solid;*/
}
.bpl2 {
  width: 50%;
  /* ---- */
  /*border: rgb(168, 173, 4) 3px solid;*/
}

.bpl2a {
  width: 50%;
  text-align: left;
}
.bpl2a > div {
  margin-top: 10px;
}

.bpl_nav {
  /*position: relative;*/
  display: inline-block;
  text-align: center;
  width: 140px;

  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;

  /* ---- */
  /*border: rgb(168, 173, 4) 3px solid;*/
}

.bpl3 {
  width: 30%;
  /* ---- */
  /*border: rgb(24, 6, 116) 3px solid;*/
}

.bpl3a {
  width: 30%;
  text-align: left;
}

.bpl3a > div {
  margin-top: 10px;
}
</style>
