<template>
  <template v-if="currUserAbrev != '...'">
    <div class="usrInitials">{{ currUserAbrev }}</div>
  </template>
  <template v-else>
    <div class="usrInitials">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00011 0.521133C10.1245 0.521133 11.2028 0.967787 11.9978 1.76284C12.7929 2.55788 13.2395 3.6362 13.2395 4.76057C13.2395 5.88494 12.7929 6.96326 11.9978 7.7583C11.2028 8.55335 10.1245 9.00001 9.00011 9.00001C7.87574 9.00001 6.79743 8.55335 6.00238 7.7583C5.20733 6.96326 4.76068 5.88494 4.76068 4.76057C4.76068 3.6362 5.20733 2.55788 6.00238 1.76284C6.79743 0.967787 7.87574 0.521133 9.00011 0.521133ZM9.00011 11.1197C13.6847 11.1197 17.479 13.0169 17.479 15.3592V17.4789H0.52124V15.3592C0.52124 13.0169 4.31554 11.1197 9.00011 11.1197Z"
          fill="#F9FAFB"
        />
      </svg>
    </div>
  </template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "avatarIcon",
  //props: ["abreviation", "image"],
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "userType",
      "currentClientId",
      "currentClientFN",
      "currentClientLN",
    ]),
    currUserAbrev() {
      let usrAbrev = "";
      let fn = "",
        ln = "";

      if (typeof this.currentClientFN != "undefined") {
        fn = this.currentClientFN;
      }
      if (typeof this.currentClientLN != "undefined") {
        ln = this.currentClientLN;
      }
      if (fn != "") {
        usrAbrev += fn[0];
      }
      if (ln != "") {
        usrAbrev += ln[0];
      }

      if (usrAbrev == "") {
        usrAbrev = "...";
      }
      return usrAbrev.toUpperCase();
    },
  },
};
</script>

<style scoped>
.usrInitials {
  /* border: 1px solid black; */
  padding: 10px;
  border-radius: 100%;
  background: linear-gradient(
    135deg,
    rgb(96, 238, 208) 0%,
    rgb(102, 142, 255) 100%
  );
  color: #fff;
  width: 25px;
  height: 25px;
}
</style>
