<template>
  <div ref="msr">
    <div v-if="dataReady" class="mysub">
      <div class="mysub_container">
        <div class="mysub_board">
          <div class="mys_top">
            <div v-if="displayAvatar" class="mys_avatar"><avatarIcon /></div>
            <div class="mys_tl">
              <div class="mys_name">{{ subscriptionName }} Plan</div>
              <div v-if="subscriptionName != 'Free'" class="mys_until">
                Until {{ currentPeriodEnd }}
              </div>
            </div>
          </div>

          <userServiceTimeUsage
            :usagePeriodEnd="currentPeriodEnd"
            mys_componentId="mysp"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { mapGetters, mapActions } from "vuex";

import avatarIcon from "@/components/elements/avatar.vue";
import userServiceTimeUsage from "@/components/elements/userServiceTimeUsage.vue";

export default {
  name: "mySubscriptionA",
  props: ["ClientId", "displayAvatar"],
  components: { avatarIcon, userServiceTimeUsage },
  setup() {
    const msr = ref(null);
    return {
      msr,
    };
  },
  data() {
    return {
      dataReady: false,
      stripeSessionUrl: null,
      license: "Free",
      urrentPeriodEnd: "",
    };
  },
  created() {
    this.$_log("MySubscription.vue created");

    if (this.isUserLoggedIn) {
      watchEffect(() => {
        this.getData();
      });
    }
  },

  computed: {
    ...mapGetters("userModule", [
      "userToken",
      "isUserLoggedIn",
      "currentUserId",
      "currentClientId",
      "currentClientFN",
      "currentClientLN",
      "currentSubscription",
    ]),

    ...mapGetters("subscriptionsModule", [
      "curentSubscriptionId",
      "subscriptionName",
      "subscriptionImage",
      "subscriptionAllowedTime",
      "subscriptionUsedTime",
      "subscriptionCurrency",
      "subscriptionStartDate",
      "subscriptionStatus",
      "subscriptionsPeriodStart",
      "subscriptionPeriodEnd",
      "subscriptionPrice",
      "subscriptionPriceId",
      "subscriptionProductId",
      "subscriptionInterval",
      "subscriptionStripeCustomerId",
    ]),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),

    ...mapActions("clientModule", {
      setCurCliId: "setCurrentId",
      setCurCliName: "setCurrentName",
    }),

    ...mapActions("subscriptionsModule", {
      getClientSubscriptions: "getClientSubscriptions",
    }),

    // =====================================
    getData() {
      this.$_log(
        "Just before getClientDataForMysubscription....getData(" +
          this.currentClientId +
          ")..."
      );

      let qObj = {
        clientId: this.currentClientId,
      };
      this.getClientSubscriptions(qObj)
        .then((r) => {
          this.currentPeriodEnd = this.$formatDate(
            new Date(r.CurrentPeriodEnd_db),
            "en_locale"
          );

          if (r.customerSubscriptions.data.length == 0) {
            this.license = "Free";
          } else {
            this.license = "Commercial";
          }
          this.$_log("==============================");
        })
        .then(() => {
          this.dataReady = true;
          if (this.license == "Commercial") {
            this.$_log("Commercial subscription ......");
          } else {
            this.stripeSessionUrl = "/msSubscriptionsView/0"; //

            this.$_log("Free test subscription ......");
          }
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
  },
};
</script>

<style scoped>
.mysub {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-gray200-color);
}
.mysub_container {
}

.mysub_board {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 40px;
}

.mysub_name {
  width: 30%;
  background: linear-gradient(
    135deg,
    rgb(96, 238, 208) 0%,
    rgb(202, 252, 232) 100%
  );
  background-blend-mode: multiply;
  padding: 24px;
  border-radius: 10px 0px 0px 10px;

  display: block;
  text-align: left;
}

.mysub_name div {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;

  align-items: flex-end;
}

.mysub_name h4 {
  margin: 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0px;
}

.mysub_name h6 {
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0px;
}

.mysub_name p {
  margin: 0px;
  font-weight: 400;
  letter-spacing: 0px;

  font-size: 14px;
  line-height: 22px;
  padding-left: 4px;
}

.mysub_name span {
  margin: 0px;
  font-weight: 400;
  letter-spacing: 0px;

  line-height: 18px;
  font-size: 12px;
  white-space: pre-line;
}

.mysub_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mys_top {
  grid-column: 1/2;
  grid-row: 1/2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.mys_tl {
  display: grid;
  align-items: center;
  justify-content: center;
}
.mys_avatar {
  grid-column: 1/2;
  grid-row: 1 / 3;
  /*background-color: red;*/
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px 0px 10px;
}
.mys_name {
  /*background-color: green;*/
  display: flex;
  align-items: flex-end;

  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}
.mys_until {
  /*background-color: magenta;*/
  display: flex;
  align-items: flex-start;

  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
</style>
