import { createRouter, createWebHistory } from "vue-router";
import GStore from "@/store";
//import { mapGetters, mapActions } from "vuex";
import { vueApp } from "@/main.js";
import NProgress from "nprogress";

//import HomeView from "../views/public/HomeView.vue"; //it rather will not be used
import pageNotFoundPage from "@/views/public/pageNotFound.vue";
import ErrorDisplay from "@/views/ErrorDisplay.vue";

import AboutView from "@/views/public/AboutView.vue";
import confirmAccount from "@/views/protected/authorization/confirmAccount.vue";
import ResetPassword from "@/views/protected/authorization/ResetPasswordView.vue";

// =============================================================================
// =============================================================================
// =============================================================================

const routes = [
  {
    path: "/",
    name: "home",
    component: AboutView,
    meta: { requireAuth: false, internalViewWithoutLogin: true },
  },

  {
    path: "/confirmAccount/:token",
    name: "confirmAccount",
    component: confirmAccount,
    props: true,
    meta: { requireAuth: false, internalViewWithoutLogin: true },
  },
  {
    path: "/rp/:token",
    name: "resetPassword",
    component: ResetPassword,
    props: true,
    meta: { requireAuth: false, internalViewWithoutLogin: true },
  },

  // =============================================================
  // P R O T E C T E D   R O U T E S >>>

  // P R O T E C T E D   R O U T E S <<<
  // =============================================================

  // -------------------------------------------------------------------------
  {
    path: "/error/:error",
    name: "ErrorDisplay",
    props: true,
    component: ErrorDisplay,
  },

  /* [404] anything else... */
  { path: "/:catchAll(.*)", component: pageNotFoundPage },
  /*{ path: "/:catchAll(.*)", redirect: { name: "login" } },*/
  // -------------------------------------------------------------------------
];

// ===========================================================================
// ===========================================================================
// ===========================================================================

// create router object ...
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// progress bar + authorization check etc ....
router.beforeEach(async (to, from, next) => {
  // show progress bar ....
  NProgress.start();

  vueApp.$_log("router beforeEach to:" + to.fullPath);

  vueApp.$_log(GStore.state.userModule.checkingState);
  if (to.meta.idp == true) {
    next();
  } else {
    if (GStore.state.userModule.checkingState == 0) {
      await GStore.dispatch("userModule/isUserLoggedIn_Action_P").then(
        (response) => {
          vueApp.$_log("router beforeEach response:" + response);

          if (response === false && to.meta.requireAuth) {
            const n = {
              type: "error",
              message: "Sorry you are not authorized",
            };
            GStore.dispatch("notificationModule/add", n, { root: true });
            next({ name: "login" });
          }

          if (typeof from.href != "undefined") {
            // <--- If this navigation came from a previous page.
            vueApp.$_log("Router beforeEach from.href IS defined:" + from.href);
            //return false;
            next();
          } else {
            // <--- if navigating directly
            vueApp.$_log("router beforeEach asw from.href is not defined! ");
            //return { path: "/" }; // <--- Push navigation to the root route.
            next();
          }
        },
        async (error) => {
          vueApp.$_log(
            "router beforeEach000	 error:",
            error.response.data.error
          );
          if (error.response.data.error == "not valid token") {
            vueApp.$_log(" ooook so lets logout and login again...");

            await GStore.dispatch("userModule/logout")
              .then(() => {
                vueApp.$_log(
                  " in router beforeEach just after logout .... current to:",
                  to
                );
                //return { name: "login" };

                vueApp.$_log(" ooook so lets reddirect route.... to: home");
                vueApp.$_log("===============================================");
                //next({ name: "home" });
                next({ name: "home" });
              })
              .catch((error) => {
                vueApp.$_log(" dammit ... error:", error);
              });
          }
        }
      );

      //
    } else {
      vueApp.$_log("somebody already checking user status....");
      next();
    }
  }
});

//
const DEFAULT_TITLE = "Muzaic – The Future of Music";
router.afterEach((to, from) => {
  vueApp.$_log("router.afterEach from:" + from + " to:" + to);
  document.title = to.meta.title || DEFAULT_TITLE;

  NProgress.done();
});

export default router;
