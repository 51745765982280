<template>
  <div
    v-if="!mobileMenu"
    class="menu-item"
    @click.prevent="isOpen = !isOpen"
    v-click-out-side="asw_clickedOutsideOfTheMenu"
  >
    <div class="usrInitialsContainer">
      <avatarIcon />
    </div>
    <!-- -->
    <transition name="fade" appear>
      <div :style="checkIfProfile(name)" class="sub-menu" v-if="isOpen">
        <div style="padding: 0 0 5px 0">
          <MySubscriptionA :ClientId="currentClientId" :displayAvatar="false" />
        </div>
        <template v-for="(item, i) in items" :key="i">
          <div
            v-if="
              (item.link != '' && item.displayAtWideScreen === true) ||
              (item.link == 'msSubscriptionsView' &&
                this.subscriptionName == 'Free')
            "
            class="menu-item"
          >
            <a href="#" @click.prevent="item.fun">
              <template
                v-if="
                  item.link == 'msSubscriptionsView' &&
                  this.subscriptionName == 'Free'
                "
              >
                <div class="menu-item-g">
                  <img
                    v-if="item.icon != ''"
                    :src="getImageUrl('Upgrade.png')"
                  />

                  <span style="color: var(--main-sucessdarker-color)"
                    >Upgrade</span
                  >
                </div>
              </template>
              <template v-else>
                <div class="menu-item-g">
                  <img v-if="item.icon != ''" :src="getImageUrl(item.icon)" />

                  <span>{{ item.title }}</span>
                </div>
              </template>
            </a>
          </div>
        </template>
      </div>
    </transition>
  </div>
  <div
    v-else
    class="menu-item"
    @click.prevent="isOpen = !isOpen"
    v-click-out-side="asw_clickedOutsideOfTheMenu"
  >
    <div class="usrMobMenuContainer">
      <img src="@/assets/menu01.png" />
    </div>
    <transition name="fade" appear>
      <div class="sub-menu-mobile" v-if="isOpen">
        <div style="padding: 0 0 5px 0">
          <MySubscriptionA :ClientId="currentClientId" :displayAvatar="true" />
        </div>

        <div v-for="(item, i) in items" :key="i" class="menu-item">
          <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
          <a v-if="item.link != ''" href="#" @click.prevent="item.fun">
            <div class="menu-item-g">
              <img v-if="item.icon != ''" :src="getImageUrl(item.icon)" />

              <span>{{ item.title }}</span>
            </div>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import InlineSvg from "vue-inline-svg";
import clickOutSide from "@mahdikhashan/vue3-click-outside";

import avatarIcon from "@/components/elements/avatar.vue";
import MySubscriptionA from "@/components/navigation/MySubscription.vue";

export default {
  name: "DropdownMenu",
  props: ["title", "items", "name"],
  components: {
    //InlineSvg,
    avatarIcon,
    MySubscriptionA,
  },

  data() {
    return {
      isOpen: false,
      mobileMenu: false,
    };
  },
  directives: {
    clickOutSide,
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "userType",
      "currentClientId",
      "currentClientFN",
      "currentClientLN",
    ]),
    ...mapGetters("subscriptionsModule", [
      "curentSubscriptionId",
      "subscriptionName",
      "subscriptionImage",
      "subscriptionAllowedTime",
      "subscriptionUsedTime",
      "subscriptionCurrency",
      "subscriptionStartDate",
      "subscriptionStatus",
      "subscriptionsPeriodStart",
      "subscriptionPeriodEnd",
      "subscriptionPrice",
      "subscriptionPriceId",
      "subscriptionProductId",
      "subscriptionInterval",
      "subscriptionStripeCustomerId",
    ]),
  },
  mounted() {
    if (window.innerWidth < 640) {
      this.mobileMenu = true;
    } else {
      this.mobileMenu = false;
    }

    window.addEventListener("resize", this.resizeWindowEventHandler_ddm);
  },
  umounted() {
    window.removeEventListener("resize", this.resizeWindowEventHandler_ddm);
  },
  methods: {
    resizeWindowEventHandler_ddm(e) {
      if (e.target.innerWidth < 640) {
        //this.$_log("resizeWindowEventHandler_ddm hmm lets resize header!");
        this.mobileMenu = true;
      } else {
        this.mobileMenu = false;
      }
    },
    asw_clickedOutsideOfTheMenu() {
      //this.$_log("You clicked outside of the menu box!");
      if (this.isOpen == true) this.isOpen = false;
    },
    checkIfProfile(n) {
      this.$_log("In case of profile menu move to the left menuName:" + n);
      if (n == "userProfile") {
        return "left: auto;";
      } else {
        return "";
      }
    },
    getImageUrl: function (imgName) {
      let images = require.context(
        "../../assets/",
        false,
        /\.png$|\.jpg$|\.svg$/
      );
      return images("./" + imgName);
    },
  },
};
</script>

<style scoped>
nav > .menu-item {
  padding: 0 0px 0 20px;
  cursor: pointer;
}
div .menu-item svg {
  width: 10px;
  margin-left: 5px;
}
div .menu-item .sub-menu {
  /*top: calc(0% + 94px);*/
  background-color: rgb(255, 255, 255);
  border-radius: 8px;

  position: absolute;
  width: 189px;
  right: 0px;
  border: 1px solid var(--main-gray200-color);
  color: var(--main-gray900-color);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 100;
}

div .menu-item .sub-menu-mobile {
  /*top: 0px;*/
  top: calc(0% - 50px);
  left: calc(0% - 103px);
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px 0px 0px 8px;

  position: absolute;
  width: 189px;
  right: 0px;
  border: 1px solid var(--main-gray200-color);
  color: var(--main-gray900-color);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 100;
}
.sub-menu-mobile .menu-item {
  padding-left: 35px;
}

.sub-menu .menu-item {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 2px;
  text-align: left;
}

.sub-menu .menu-item a {
  display: block;
  text-align: left;
  padding-left: 5px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #000;
}

.menu-item a {
  text-align: left;
  padding-left: 5px;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  font-size: 14px;
}
.menu-item a img {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.menu-item-g {
  display: flex;
  align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.dropDownIcon {
  padding: 8px;
  translate: 50% 25%;
  /*border: 1px solid #ccc;*/
  border-radius: 100%;
  background-color: #f5f5f5;
}
.usrMobMenuContainer {
  position: relative;
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.usrInitialsContainer {
  position: relative;
  padding: 0 0px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
