import PageModule from "@/store/modules/generic/genericPageModule.js";
import stripeService from "@/services/stripeService.js";

var page = new PageModule();
const namespaced = true;

// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
const state = {
  currentSubscriptions: {
    id: null,
    name: "Free",
    image: null,
    alreadyUsedTime: 0,
    allowedMonthlyTime: 3600,
    currency: "$",
    start_date: null,
    status: "active",
    current_period_start: null,
    current_period_end: null,
    price: null,

    price_id: null,
    product_id: null,
    interval: null,
    StripeCustomerId: null,
  },

  ...page.state,
};

// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
const getters = {
  ...page.getters,

  curentSubscriptionId(state) {
    return state.currentSubscriptions.id;
  },
  subscriptionName(state) {
    return state.currentSubscriptions.name;
  },
  subscriptionImage(state) {
    return state.currentSubscriptions.image;
  },
  subscriptionAllowedTime(state) {
    //return state.currentSubscriptions.allowedMonthlyTime;
    return (
      state.currentSubscriptions.allowedMonthlyTime +
      state.currentSubscriptions.alreadyUsedTime
    );
  },
  subscriptionUsedTime(state) {
    return state.currentSubscriptions.alreadyUsedTime;
  },
  subscriptionCurrency(state) {
    return state.currentSubscriptions.currency;
  },
  subscriptionStartDate(state) {
    return state.currentSubscriptions.start_date;
  },
  subscriptionStatus(state) {
    return state.currentSubscriptions.status;
  },

  subscriptionsPeriodStart(state) {
    return state.currentSubscriptions.current_period_start;
  },
  subscriptionPeriodEnd(state) {
    return state.currentSubscriptions.current_period_end;
  },
  subscriptionPrice(state) {
    return state.currentSubscriptions.price;
  },
  subscriptionPriceId(state) {
    return state.currentSubscriptions.price_id;
  },
  subscriptionProductId(state) {
    return state.currentSubscriptions.product_id;
  },
  subscriptionInterval(state) {
    return state.currentSubscriptions.interval;
  },
  subscriptionStripeCustomerId(state) {
    return state.currentSubscriptions.StripeCustomerId;
  },

  curentSubscription(state) {
    return state.currentSubscriptions;
  },
};

// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
const mutations = {
  ...page.mutations,

  SET_INIT_SUBSCRIPTION(state, data) {
    state.currentSubscriptions.id = data.id;
    state.currentSubscriptions.name = data.name;
    state.currentSubscriptions.image = data.image;
    state.currentSubscriptions.alreadyUsedTime = data.alreadyUsedTime;
    state.currentSubscriptions.allowedMonthlyTime = data.allowedMonthlyTime;
    state.currentSubscriptions.currency = data.currency;
    state.currentSubscriptions.start_date = data.start_date;
    state.currentSubscriptions.status = data.status;
    state.currentSubscriptions.current_period_start = data.current_period_start;
    state.currentSubscriptions.current_period_end = data.current_period_end;
    state.currentSubscriptions.price = data.price;

    state.currentSubscriptions.price_id = data.price_id;
    state.currentSubscriptions.product_id = data.product_id;
    state.currentSubscriptions.interval = data.interval;
    state.currentSubscriptions.StripeCustomerId = data.StripeCustomerId;
  },

  SET_CURRENT_SUB(state, dataObj) {
    state.currentSubscriptions = dataObj;
  },

  SET_SUB_NAME(state, name) {
    state.currentSubscriptions.name = name;
  },
  SET_SUB_IMAGE(state, image) {
    state.currentSubscriptions.image = image;
  },
  SET_SUB_PRICE(state, price) {
    state.currentSubscriptions.price = price;
  },
  SET_SUB_UsedTime(state, usedTime) {
    state.currentSubscriptions.alreadyUsedTime = usedTime;
  },
  SET_SUB_AllowedTime(state, allowedTime) {
    state.currentSubscriptions.allowedMonthlyTime = allowedTime;
  },
  SET_SUB_PeriodStart(state, PeriodStart) {
    state.currentSubscriptions.current_period_start = PeriodStart;
  },

  SET_SUB_PeriodEnd(state, PeriodEnd) {
    state.currentSubscriptions.current_period_end = PeriodEnd;
  },
};

// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
const actions = {
  ...page.actions,

  // =====================================================
  initSub({ commit }, jsonObj) {
    commit("SET_INIT_SUBSCRIPTION", jsonObj);
  },
  // =====================================================
  getClientSubscriptions({ dispatch, commit }, jsonObj) {
    return new Promise((resolve, reject) => {
      stripeService
        .getClientDataForMysubscription(jsonObj.clientId)
        .then((r) => {
          dispatch(
            "asw_log",
            ["getClientDataForMysubscription response:", r.data],
            {
              root: true,
            }
          );
          let tmpObj = {};
          if (r.data.customerSubscriptions.data.length > 0) {
            tmpObj = {
              id: r.data.customerSubscriptions.data[0].id,
              name: r.data.customerSubscriptions.data[0].items.data[0].plan
                .metadata.SubscriptionLevel,
              image: null,
              alreadyUsedTime: r.data.alreadyUsedTime,
              allowedMonthlyTime: parseInt(
                r.data.customerSubscriptions.data[0].items.data[0].plan.metadata
                  .nrSecondsToBeUsedMonthly,
                10
              ),
              currency:
                r.data.customerSubscriptions.data[0].currency == "usd"
                  ? "$"
                  : r.data.customerSubscriptions.data[0].currency,
              start_date: r.data.customerSubscriptions.data[0].start_date,
              status: r.data.customerSubscriptions.data[0].status,

              current_period_start:
                r.data.customerSubscriptions.data[0].current_period_start,
              current_period_end:
                r.data.customerSubscriptions.data[0].current_period_end,
              price:
                r.data.customerSubscriptions.data[0].items.data[0].plan.amount /
                100,

              price_id:
                r.data.customerSubscriptions.data[0].items.data[0].plan.id,
              product_id:
                r.data.customerSubscriptions.data[0].items.data[0].plan.product,
              interval:
                r.data.customerSubscriptions.data[0].items.data[0].plan
                  .interval,
              StripeCustomerId: r.data.StripeCustomerId,
              minTrackDuration: 10,
              maxTrackDuration: 1200,
            };
          } else {
            tmpObj = {
              id: null,
              name: "Free",
              image: null,
              alreadyUsedTime: r.data.alreadyUsedTime,
              allowedMonthlyTime: r.data.UnitsAvailable_db,
              currency: "$",
              start_date: null,
              status: "active",

              current_period_start: r.data.CurrentPeriodStart_db,
              current_period_end: r.data.CurrentPeriodEnd_db,
              price: 0,

              price_id: null,
              product_id: null,
              interval: "month",
              StripeCustomerId: r.data.StripeCustomerId,
              minTrackDuration: 10,
              maxTrackDuration: 1200,
            };
          }

          commit("SET_INIT_SUBSCRIPTION", tmpObj);

          resolve(r.data);
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 403 && error.response.data.status) {
              const n = {
                type: "error",
                message: "Incorrect client data.",
              };
              dispatch("notificationModule/add", n, { root: true });
              reject(error);
            }
          } else {
            const n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
            reject(error);
          }
        });
    });
  },
};

export { namespaced, state, getters, mutations, actions };
